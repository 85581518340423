import * as React from 'react';

import { AppPageLayout, AppPageLinks } from 'src/app.consumer/components';
import { Localized, Typography } from 'src/app/components';
import { AppProps, SeoObject } from 'src/app/types';

const seo: SeoObject = {
  title: 'Privacy policy',
};

export const PrivacyPolicyPage = (props: AppProps) => {
  return (
    <AppPageLayout
      sidebar={<AppPageLinks />}
      subTitle={<Localized dictKey={'staticLocales:pages./privacy-policy.subtitle'} />}
      title={<Localized dictKey={'staticLocales:pages./privacy-policy.link label'} />}
      {...{ ...props, seo }}
    >
      <Typography variant={'body1'}>        
        <Localized dictKey={'staticLocales:pages./privacy-policy.content'} />
      </Typography>
    </AppPageLayout>
  );
};

export default PrivacyPolicyPage;
